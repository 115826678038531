import * as React from "react";
import { BaseLayout } from "../../shared/components/BaseLayout";
import { Link, useParams } from "react-router-dom";
import { useApiModels } from "../../shared/hooks/useApiModels";
import { Button, Input, Modal, Space, Table, Typography } from "antd";
import {
    InputConnectorsApi,
    InputRecordRow,
    InputRecordRowsApi,
    InputRecordsApi,
} from "../../client";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../shared/constants";
import { Status } from "../../shared/components/Status";
import { RawDataPreview } from "../../shared/components/RawDataPreview";
import { useApiModel } from "../../shared/hooks/useApiModel";
import { diffBetweenMomentsAsString } from "../../shared/functions";

interface IProps {}

export const InputRecordDetail: React.FC<IProps> = (props) => {
    const inputRecordId = useParams<{ id: string }>().id;
    const [searchValue, setSearchValue] = React.useState("");

    const { model: inputRecord } = useApiModel(inputRecordId || null, (id) =>
        new InputRecordsApi().retrieveInputRecord({
            id,
        })
    );

    const { model: inputConnector } = useApiModel(
        (inputRecord && inputRecord.input_connector_id) || null,
        (id) => new InputConnectorsApi().retrieveInputConnector({ id })
    );

    const {
        models: inputRecordRows,
        loading,
        tableProps,
        fetch,
    } = useApiModels(
        (page, filters) =>
            new InputRecordRowsApi().listInputRecordRows({
                page,
                inputRecordId,
                ordering: "created",
                all: searchValue,
                ...filters,
            }),
        "inputRecords"
    );

    const retryConvert = () => {
        Modal.confirm({
            title: "Are you sure you want to convert the failing row(s) (again)?",
            onOk: () => {
                if (!inputRecordId) {
                    return;
                }
                return new InputRecordsApi()
                    .retryConvertInputRecord({ id: inputRecordId })
                    .then(() => fetch());
            },
        });
    };

    const retryExport = () => {
        Modal.confirm({
            title: "Are you sure you want to export the failing row(s) (again)?",
            onOk: () => {
                if (!inputRecordId) {
                    return;
                }
                return new InputRecordsApi()
                    .retryExportInputRecord({ id: inputRecordId })
                    .then(() => fetch());
            },
        });
    };

    return (
        <BaseLayout>
            <Typography.Title level={2}>
                {(inputConnector && inputConnector.name) || ""}
            </Typography.Title>

            <div style={{ marginBottom: "1em" }}>
                <Space>
                    <Input.Search
                        placeholder="search..."
                        loading={loading}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onSearch={() => fetch()}
                    />

                    <Button
                        onClick={() =>
                            window.open(
                                `/api/input_records/${inputRecordId}/download/`
                            )
                        }
                    >
                        Download input
                    </Button>

                    <Button onClick={() => retryConvert()}>
                        Retry conversion for failing rows
                    </Button>

                    <Button onClick={() => retryExport()}>
                        Retry export for failing rows
                    </Button>
                </Space>
            </div>

            <Table<InputRecordRow>
                dataSource={inputRecordRows}
                loading={loading}
                rowKey="id"
                {...tableProps}
                columns={[
                    {
                        key: "row_identifier",
                        dataIndex: "row_identifier",
                        title: "#",
                        width: "75px",
                    },
                    {
                        key: "is_converted",
                        dataIndex: "is_converted",
                        title: "Converted",
                        width: "100px",
                        render: (tmsOrderId, record) => (
                            <Status
                                value={Boolean(
                                    record.group &&
                                        (record.group.tms_order_id ||
                                            record.group.converted_data !==
                                                "" ||
                                            record.group.converted_file !==
                                                "" ||
                                            record.group.is_skipped ||
                                            record.group.is_exported)
                                )}
                            />
                        ),
                    },
                    {
                        key: "is_exported",
                        dataIndex: "is_exported",
                        title: "Exported",
                        width: "100px",
                        render: (_, record) => {
                            let isExported: boolean | null = false;
                            if (record.group) {
                                if (record.group.is_exported) {
                                    isExported = true;
                                } else if (record.group.is_skipped) {
                                    isExported = null;
                                }
                            }
                            return <Status value={isExported} />;
                        },
                        filterMultiple: false,
                        filters: [
                            {
                                text: "Yes",
                                value: true,
                            },
                            {
                                text: "No",
                                value: false,
                            },
                        ],
                    },
                    {
                        key: "is_skipped",
                        dataIndex: "group.is_skipped",
                        title: "Skipped",
                        width: "100px",
                        render: (_, record) => (
                            <Status
                                value={
                                    record.group
                                        ? !!record.group.is_skipped
                                        : null
                                }
                            />
                        ),
                    },
                    {
                        key: "created",
                        dataIndex: "created",
                        title: "Created",
                        width: "200px",
                        render: (created) =>
                            moment(created).format(DATE_TIME_FORMAT),
                    },
                    {
                        key: "exported_on",
                        dataIndex: "exported_on",
                        title: "Exported on",
                        width: "200px",
                        render: (_, record) =>
                            record.group && record.group.exported_on
                                ? moment(record.group.exported_on).format(
                                      DATE_TIME_FORMAT
                                  )
                                : "-",
                    },
                    {
                        key: "export_time",
                        dataIndex: "export_time",
                        title: "Time to export (mm:ss:fff)",
                        width: "100px",
                        render: (_, record) =>
                            record.group && record.group.exported_on
                                ? diffBetweenMomentsAsString(
                                      moment(record.created),
                                      moment(record.group.exported_on)
                                  )
                                : "-",
                    },
                    {
                        key: "group_id",
                        dataIndex: "group_id",
                        title: "Group ID",
                        width: "150px",
                        render: (_, record) =>
                            record.group && (
                                <Link to={`/groups/${record.group.id}`}>
                                    {record.group.id}
                                </Link>
                            ),
                    },
                    {
                        key: "raw",
                        dataIndex: "raw",
                        title: "Raw input data",
                        ellipsis: true,
                        render: (raw) => <RawDataPreview raw={raw} />,
                    },
                ]}
            />
        </BaseLayout>
    );
};
